import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { parse as parseQueryString, stringify as stringifyQueryString } from 'query-string';
import compose from '../utils/compose/compose.js';
import { MyProfileEditForm, SidePanel, UserEditFailure, UserEditProcessing, UserEditSuccess } from '../components';
import UserEditLinkAccountsFailure from '../components/UserEditLinkAccountsFailure/UserEditLinkAccountsFailure';
import withFormHandlers from '../decorators/withFormHandlers';
import withLocalizedContent from '../language/withLocalizedContent';
import actions from '../redux/actions';
import UserRoles from '../globals/userRoles';
import { socialIdpIsEnabled, getIntegrationPlatformKey } from '../globals/envSettings';
import { getCurrentPlatform, epsPlatformOlbOffline } from '../utils/platform';
import { prepareTargetUrl, getProviderId } from '../utils/url';
import session from '../utils/session';
import initAuth0, { LINK_ACCOUNTS_STATUS } from '../globals/oidcSettings';
import { postNotificationMessage } from '../redux/actions/postMessage';

const IDENTITY_FORM = 'IDENTITY_FORM';
const AUTH_FORM = 'AUTH_FORM';
const SOCIAL_FORM = 'SOCIAL_FORM';
const UNLINK_SOCIAL_CONFIRM = 'UNLINK_SOCIAL_CONFIRM';
class MyProfileEditPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { form: IDENTITY_FORM, linkUserAccountsData: null };
  }

  componentWillMount() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.isOpen) {
      this._getUserDetails();
      this._getSocialIdentities();
    }
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!prevProps.isOpen && this.props.isOpen) {
      this._getUserDetails();
      this._getSocialIdentities();
    }
  }

  _goToFormWithData = (form, linkUserAccountsData) => {
    this.setState({ linkUserAccountsData });
    this.setState({ form });
  };

  _goToForm = form => {
    this.setState({ form });
  };

  _getUserDetails = () => {
    const { getUserDetails } = this.props;
    getUserDetails();
  };

  _editIdentity = () => {
    const { formInputChanges, editIdentity } = this.props;
    const identityFormInputs = ['firstName', 'lastName'];
    const sentData = {
      ...pick(this.props, identityFormInputs),
      ...pick(formInputChanges, identityFormInputs)
    };
    editIdentity(sentData);
  };

  _unlinkSocialAccounts = dataObject => {
    const { unlinkSocialAccounts, formInputChanges, primaryUserId, userName } = this.props;
    const body = dataObject.body;
    body.password = formInputChanges.socialConfirmPassword;
    body.username = userName;
    unlinkSocialAccounts(primaryUserId, body);
  };

  _getSocialIdentities = async () => {
    if (socialIdpIsEnabled(getCurrentPlatform())) {
      const { getSocialIdentities } = this.props;
      getSocialIdentities();
    }
  };

  _editAuth = () => {
    const {
      formInputChanges: { oldPassword = '', ...formInputChanges },
      editAuth,
      isSupervised,
      userAccount
    } = this.props;
    const authFormInputs = ['userName', 'newPassword'];
    let sentData = {
      ...pick(this.props, authFormInputs),
      ...pick(formInputChanges, [...authFormInputs, 'email']),
      origUserName: userAccount.userName,
      oldPassword
    };

    if (isSupervised) {
      sentData = {
        ...sentData,
        origUserName: userAccount.email,
        userName: sentData.origUserName === sentData.userName ? userAccount.email : sentData.userName,
        email: sentData.origUserName === sentData.email || !sentData.email ? userAccount.email : sentData.email,
        isSupervised: true
      };
    }
    editAuth(sentData);
  };

  _resetPanel = () => {
    const { onClearFormChanges, reset } = this.props;
    localStorage.removeItem('linkAccountsStatus');
    onClearFormChanges();
    reset();
    this.setState({ form: IDENTITY_FORM });
    this._getSocialIdentities();
  };

  _resetMyProfileEditPanel = () => {
    this._resetPanel();
    this._goToForm(AUTH_FORM);
  };

  _resetMySocialLinkConfirmPanel = () => {
    this._resetPanel();
    this._goToForm(SOCIAL_FORM);
  };

  _resetMySocialUnlinkConfirmPanel = () => {
    this._resetPanel();
    this._goToForm(UNLINK_SOCIAL_CONFIRM);
  };

  // eslint-disable-next-line class-methods-use-this
  _getLinkAccountStatus = () => {
    const linkAccountsStatus = localStorage.getItem('linkAccountsStatus');

    if (linkAccountsStatus !== null) {
      return (
        (linkAccountsStatus === LINK_ACCOUNTS_STATUS.success && LINK_ACCOUNTS_STATUS.success) ||
        (linkAccountsStatus === LINK_ACCOUNTS_STATUS.alreadyLinked && LINK_ACCOUNTS_STATUS.alreadyLinked) ||
        LINK_ACCOUNTS_STATUS.error
      );
    }

    return '';
  };

  // eslint-disable-next-line class-methods-use-this
  _prepareQsParams = () => {
    const { protocol, target_url: targetUrl } = parseQueryString(window.location.search);
    const qsParams = {};

    if (targetUrl) {
      qsParams.target_url = targetUrl;

      if (protocol) {
        qsParams.protocol = protocol;
      }
    }

    return qsParams.target_url ? `?${stringifyQueryString(qsParams)}` : '';
  };

  _closePanel = async () => {
    const redirectTo = prepareTargetUrl();
    const providerId = getProviderId();
    const { postMessageAction } = this.props;

    postMessageAction({ eventName: 'INTERACTION_COMPLETE', viewId: '*' });
    this._resetPanel();

    const logoutParams = {
      logoutParams: {
        // eslint-disable-next-line no-undef
        returnTo: process.env.CES_BASE_URL
      },
      openUrl: url => fetch(url)
    };

    if (redirectTo) {
      switch (true) {
        case providerId && getIntegrationPlatformKey(providerId) === epsPlatformOlbOffline:
          // eslint-disable-next-line no-restricted-globals
          location.href = `/offlineApp/EDIT_ACCOUNT_SUCCES${this._prepareQsParams()}`;
          break;
        case !!providerId:
          session.clear();

          try {
            const initAuth0Instance = await initAuth0();
            await initAuth0Instance.logout(logoutParams);
          } catch (error) {
            console.log('Auth0 logout error: ', error);
          }

          // eslint-disable-next-line no-restricted-globals
          location.href = redirectTo;
          break;
        default:
          session.clear();
          // eslint-disable-next-line no-restricted-globals
          location.href = redirectTo;
      }
    }

    // eslint-disable-next-line react/destructuring-assignment
    this.props.closePanel();
  };

  _renderContent = () => {
    const {
      orgId,
      hasFailed,
      hasFailedUnlink,
      isPasswordWrong,
      isLoading,
      isSubmitting,
      isSuccessful,
      onBlurWithParams,
      userId,
      unlinkErrorCode,
      unlinkErrorMessage,
      errorCode,
      hasOTCEntitlement
    } = this.props;

    const linkAccountStatus = this._getLinkAccountStatus();
    const unlinkErrorWrongPassword = unlinkErrorCode === 403 || unlinkErrorMessage === 'Unauthorised Request!';
    const wrongCurrentPassword = errorCode === 'current_password_invalid';

    switch (true) {
      case isLoading:
        return <UserEditProcessing isLoading />;
      case hasFailedUnlink && isPasswordWrong:
        return (
          <UserEditFailure
            failureMessage
            unlinkErrorWrongPassword={unlinkErrorWrongPassword}
            wrongCurrentPassword={wrongCurrentPassword}
            onBack={this._resetMySocialUnlinkConfirmPanel}
            onDone={this._closePanel}
          />
        );
      case linkAccountStatus === LINK_ACCOUNTS_STATUS.error:
        return <UserEditFailure false onBack={this._resetMySocialLinkConfirmPanel} onDone={this._closePanel} />;
      case linkAccountStatus === LINK_ACCOUNTS_STATUS.alreadyLinked:
        return <UserEditLinkAccountsFailure onBack={this._resetMySocialLinkConfirmPanel} onDone={this._closePanel} />;
      case hasFailed:
        return (
          <UserEditFailure
            failureMessage
            wrongCurrentPassword={wrongCurrentPassword}
            onBack={this._resetMyProfileEditPanel}
            onDone={this._closePanel}
          />
        );
      case isSubmitting:
        return <UserEditProcessing isWaiting />;
      case isSubmitting && isLoading:
        return <UserEditProcessing isLoading />;
      case isSuccessful || linkAccountStatus === LINK_ACCOUNTS_STATUS.success:
        return (
          <UserEditSuccess
            isSuccessful
            {...pick(this.props, ['firstName', 'lastName', 'userName'])}
            onBack={this._resetPanel}
            onDone={this._closePanel}
          />
        );
      default:
        return (
          <MyProfileEditForm
            {...pick(this.props, [
              'firstName',
              'lastName',
              'userName',
              'email',
              'isSupervised',
              'formInputChanges',
              'formInputErrors',
              'isCheckingUserNameTaken',
              'isUserNameTaken',
              'isCheckingEmailTaken',
              'isEmailTaken',
              'onChange',
              'isOupRole',
              'userAccount',
              'unlinkErrorCode',
              'unlinkErrorMessage',
              'errorCode'
            ])}
            onBlur={onBlurWithParams(orgId)}
            editIdentity={this._editIdentity}
            editAuth={this._editAuth}
            unlinkSocialAccounts={this._unlinkSocialAccounts}
            getSocialIdentities={this._getSocialIdentities}
            // eslint-disable-next-line react/destructuring-assignment
            form={this.state.form}
            // eslint-disable-next-line react/destructuring-assignment
            linkUserAccountsData={this.state.linkUserAccountsData}
            userId={userId}
            goToForm={this._goToForm}
            goToFormWithData={this._goToFormWithData}
            hasOTCEntitlement={hasOTCEntitlement}
          />
        );
    }
  };

  render() {
    const {
      localizedContent: { myProfileEditPanel: content },
      formInputChanges,
      isOpen,
      isSuccessful
    } = this.props;

    return (
      <SidePanel
        id="MyProfileEdit"
        ariaLabel={content.title}
        confirmBeforeClose={!isSuccessful && !!Object.keys(formInputChanges).length}
        isOpen={isOpen}
        onClose={this._closePanel}
      >
        {this._renderContent()}
      </SidePanel>
    );
  }
}
MyProfileEditPanel.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isSupervised: PropTypes.bool.isRequired,
  formInputChanges: PropTypes.object.isRequired,
  formInputErrors: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isCheckingUserNameTaken: PropTypes.bool.isRequired,
  isUserNameTaken: PropTypes.bool.isRequired,
  isCheckingEmailTaken: PropTypes.bool.isRequired,
  isEmailTaken: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isSuccessful: PropTypes.bool.isRequired,
  hasFailed: PropTypes.bool.isRequired,
  hasFailedUnlink: PropTypes.bool.isRequired,
  isPasswordWrong: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlurWithParams: PropTypes.func.isRequired,
  onClearFormChanges: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  editIdentity: PropTypes.func.isRequired,
  editAuth: PropTypes.func.isRequired,
  unlinkSocialAccounts: PropTypes.func,
  getSocialIdentities: PropTypes.func,
  reset: PropTypes.func.isRequired,
  closePanel: PropTypes.func.isRequired,
  isOupRole: PropTypes.bool.isRequired,
  primaryUserId: PropTypes.string,
  userAccount: PropTypes.object,
  postMessageAction: PropTypes.func,
  unlinkErrorCode: PropTypes.number,
  unlinkErrorMessage: PropTypes.string,
  errorCode: PropTypes.string,
  hasOTCEntitlement: PropTypes.bool
};
export default compose(
  withLocalizedContent('myProfileEditPanel'),
  connect(
    ({ identity, editUser, userPlatformStatistics }) => ({
      ...pick(editUser, [
        'firstName',
        'lastName',
        'userName',
        'email',
        'selfSelectedRole',
        'claimedSchool',
        'countryCode',
        'isSupervised',
        'isLoading',
        'isCheckingUserNameTaken',
        'isUserNameTaken',
        'isCheckingEmailTaken',
        'isEmailTaken',
        'isSubmitting',
        'isSuccessful',
        'hasFailed',
        'hasFailedUnlink',
        'isPasswordWrong',
        'primaryUserId',
        'userAccount',
        'unlinkErrorCode',
        'unlinkErrorMessage',
        'errorCode'
      ]),
      hasOTCEntitlement: Object.keys(userPlatformStatistics.redeemed).includes('otc'),
      userId: identity.userId,
      orgId: identity.currentOrganisationId,
      formInputErrors: editUser.errors,
      isOupRole: [UserRoles.OUP_ADMIN, UserRoles.OUP_SUPPORT].includes(identity.role)
    }),
    {
      getUserDetails: actions.editUserGetDetailsRequest,
      validate: actions.editUserValidateInput,
      editIdentity: actions.editUserIdentityRequest,
      editAuth: actions.editUserAuthRequest,
      reset: actions.editUserReset,
      editUserFailure: actions.editUserFailure,
      unlinkSocialAccounts: actions.editUserUnlinkSocialAccounts,
      getSocialIdentities: actions.editUserGetSocialIdentitiesRequest,
      postMessageAction: postNotificationMessage
    }
  ),
  withFormHandlers()
)(MyProfileEditPanel);

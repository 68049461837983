import React from 'react';
import PropTypes from 'prop-types';
import { Button, TextLink } from '@oup/shared-front-end';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import styles from './EditUserAccount.scss';

function EditUserAccountError({ hubContent, onTryAgain }) {
  return (
    <div>
      <PopoutPanelIllustrationHeading
        title={hubContent.user_account_updated_error}
        subtitle={hubContent.user_account_updated_error_subtitle}
        illustrationSrc={HubIllustrationConstants.ERROR}
        illustrationAltText={HubIllustrationAltText.ERROR}
      >
        <div className={styles.emptyStateChildrenContainer}>
          <Button id="updateDetailsTryAgainButton" text={hubContent.try_again} onClick={onTryAgain} />
          <TextLink to={hubContent.contact_us_link} underline target="_blank">
            {`${hubContent.contact} ${hubContent.customer_support}`}
          </TextLink>
        </div>
      </PopoutPanelIllustrationHeading>
    </div>
  );
}

EditUserAccountError.propTypes = {
  hubContent: PropTypes.object.isRequired,
  onTryAgain: PropTypes.func.isRequired
};

export default EditUserAccountError;

/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import '../../styles/base/_index.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { TextLink, Button } from '@oup/shared-front-end';
import { PLATFORMS } from '@oup/shared-node-browser/constants';

import withRouter from '../../utils/withRouter';
import compose from '../../utils/compose/compose.js';
import { getIdpLoginUrl } from '../../redux/sagas/preRender/auth/getCredentials/redirectToIdP.saga.js';
import withLocalizedContent from '../../language/withLocalizedContent';
import styles from './RegisterChoice.scss';
import colors from '../../globals/colors.js';
import { GLYPHS } from '../../components/SVGIcon/SVGIcon.js';
import { SIZES as thumbnailSizes } from '../../components/Thumbnail/Thumbnail.js';

import CardWithThumbnail from '../../components/CardWithThumbnail/CardWithThumbnail.js';
import { sanitizeUrl, prepareTargetUrl, getProviderId } from '../../utils/url';
import { isHubMode, getCurrentPlatform, isOlbOdsMode } from '../../utils/platform.js';
import { isIosWebview, isAndroidWebview } from '../../utils/device';
import { googleSignIn, microsoftSignIn, appleSignIn } from '../../redux/reducers/registration/registration.reducer';
import {
  getPlatformDashboardUrl,
  useInternalAuthProcess,
  getIntegrationPlatformKey,
  socialIdpIsEnabled,
  getPlatformOaldLoginUrl,
  featureIsEnabled
} from '../../globals/envSettings';

class RegisterChoice extends Component {
  _handleSignIn = async () => {
    const { platform, location: locationParam } = this.props;
    const query = new URLSearchParams(locationParam.search);

    if (
      (isIosWebview() || isAndroidWebview() || isOlbOdsMode()) &&
      window &&
      window.olbOfflineClient &&
      typeof window.olbOfflineClient.reloadSignInWebpage === 'function'
    ) {
      window.olbOfflineClient.reloadSignInWebpage();
      return;
    }

    const redirectTo = prepareTargetUrl();
    const providerId = getProviderId();
    const params = { withReturnTo: false };

    if (providerId) {
      const platformKey = getIntegrationPlatformKey(providerId);
      params.providerId = providerId;
      params.platform = (providerId || '').toUpperCase();

      // redirect to dashboard workaround https://oupagile.atlassian.net/browse/EPS-6767
      if (useInternalAuthProcess(platformKey)) {
        let url = '';
        if (PLATFORMS.OALD === providerId) {
          url = getPlatformOaldLoginUrl(platformKey);
        } else {
          url = getPlatformDashboardUrl(platformKey);
        }
        window.location.href = url;
        return;
      }
    }

    if (redirectTo) {
      // attention: this URL must be added to Auth0 client callbacks list
      params.redirect_uri = redirectTo;
    } else {
      params.platform = params.platform || platform;
      params.targetUrl = sanitizeUrl(query.get('return_url'));
    }

    const url = await getIdpLoginUrl(null, params);

    window.location.href = url;
  };

  render() {
    const {
      localizedContent: { registerChoice: content },
      onGoogleSignInClick,
      onMicrosoftSignInClick,
      onAppleSignInClick,
      emailRegister
    } = this.props;
    const appleLoginIsEnabled = featureIsEnabled('enable-apple-login');
    return (
      <div className={styles.registerChoiceBg}>
        {isHubMode() ? (
          <Helmet title={content.title} titleTemplate="%s | Oxford University Press" />
        ) : (
          <Helmet title={content.title} />
        )}
        <div className="grid">
          <div className={`row ${styles.registerChoiceRow}`}>
            <div className={`col sm7 md6 lg5 ${styles.registerChoiceCol}`}>
              <CardWithThumbnail
                thumbnailSize={thumbnailSizes.HEADING}
                thumbnailColor={colors.PRIMARY_BLUE_HOVER}
                thumbnailGlyph={GLYPHS.ICON_USER}
              >
                <div style={{ margin: '-15px 5px 20px' }}>
                  <div style={{ textAlign: 'center' }}>
                    <h1 style={{ fontSize: '26px' }}>{content.register_choice_page_heading}</h1>
                  </div>
                  <div className={`pad-top2 ${styles.registerChoiceItems}`}>
                    <Button text={content.register_choice_email} variant="filled" onClick={emailRegister} />
                  </div>
                  <div className={`pad-top2 ${styles.registerOxfordIdRow}`}>
                    {content.register_choice_top_text_start}{' '}
                    <TextLink
                      className={styles.signInLink}
                      onClick={e => {
                        e.preventDefault();
                        this._handleSignIn();
                      }}
                      to="#"
                    >
                      {content.register_choice_link_text}
                    </TextLink>
                  </div>
                  {socialIdpIsEnabled(getCurrentPlatform()) ? (
                    <>
                      <div className={`pad-top2 ${styles.registerSubtext}`}>
                        <hr />
                        {content.register_choice_or}
                        <hr />
                      </div>
                      <>
                        {appleLoginIsEnabled ? (
                          <div className={`pad-top2 ${styles.registerChoiceItems}`}>
                            <button
                              id="registration-apple-social"
                              className={styles.registerButtonApple}
                              onClick={onAppleSignInClick}
                            >
                              <img alt="" src="../../../static/images/social-icons/apple.svg" />
                              {content.register_choice_apple_sign_in}
                            </button>
                          </div>
                        ) : null}
                        <div className={`pad-top1 ${styles.registerChoiceItems}`}>
                          <button
                            id="registration-google-social"
                            className={styles.registerButtonGoogle}
                            onClick={onGoogleSignInClick}
                          >
                            <img alt="" src="../../../static/images/social-icons/google.svg" />
                            {content.register_choice_google_sign_in}
                          </button>
                        </div>
                        <div className={`pad-top1 ${styles.registerChoiceItems}`}>
                          <button
                            id="registration-microsoft-social"
                            className={styles.registerButtonMicrosoft}
                            onClick={onMicrosoftSignInClick}
                          >
                            <img alt="" src="../../../static/images/social-icons/microsoft.svg" />
                            {content.register_choice_microsoft_sign_in}
                          </button>
                        </div>
                      </>
                      <div className={`pad-top2 ${styles.registerChoiceItems}`}>
                        <div className={styles.linkText}>
                          {appleLoginIsEnabled
                            ? content.register_choice_bottom_text_start_with_apple
                            : content.register_choice_bottom_text_start}{' '}
                          {content.register_choice_bottom_text_middle} <b>{content.register_choice_bottom_text_edit}</b>{' '}
                          {content.register_choice_bottom_text_then}{' '}
                          <b>
                            {appleLoginIsEnabled
                              ? content.register_choice_bottom_text_end_with_apple
                              : content.register_choice_bottom_text_end}
                          </b>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </CardWithThumbnail>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RegisterChoice.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  onGoogleSignInClick: PropTypes.func,
  onMicrosoftSignInClick: PropTypes.func,
  onAppleSignInClick: PropTypes.func,
  emailRegister: PropTypes.func,
  platform: PropTypes.string,
  location: PropTypes.object
};

export const connectRedux = connect(
  () => ({}),

  (dispatch, props) => ({
    onGoogleSignInClick: () => {
      dispatch(googleSignIn());
    },
    onMicrosoftSignInClick: () => {
      dispatch(microsoftSignIn());
    },
    onAppleSignInClick: () => {
      dispatch(appleSignIn());
    },
    emailRegister: () => {
      props.history.push(`/register${window.location.search || ''}`);
    }
  })
);
export default compose(withLocalizedContent('registerChoice'), withRouter, connectRedux)(RegisterChoice);

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@oup/shared-front-end';
import { HubIllustrationConstants, HubIllustrationAltText } from '../../globals/hubConstants';
import PopoutPanelIllustrationHeading from '../PopoutPanelIllustrationHeading/PopoutPanelIllustrationHeading';
import styles from './EditUserAccount.scss';

function EditUserAccountConfirmation({ hubContent, onSuccessContinue }) {
  return (
    <div>
      <PopoutPanelIllustrationHeading
        title={hubContent.user_account_updated_success}
        illustrationSrc={HubIllustrationConstants.SUCCESS}
        illustrationAltText={HubIllustrationAltText.SUCCESS}
      >
        <div className={styles.emptyStateChildrenContainer}>
          <Button id="updateDetailsContinueButton" text={hubContent.continue} onClick={onSuccessContinue} />
        </div>
      </PopoutPanelIllustrationHeading>
    </div>
  );
}

EditUserAccountConfirmation.propTypes = {
  hubContent: PropTypes.object.isRequired,
  onSuccessContinue: PropTypes.func.isRequired
};

export default EditUserAccountConfirmation;
